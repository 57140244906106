.spacer5 {
    padding-bottom: 10px;
  }

  .btn {
      width: 15vw !important;
      text-align: center !important;
  }

  .titulo {
    text-align: center !important;
    background-color: #0d6efd;
    color: #FFFFFF;

  }